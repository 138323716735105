import React from 'react';
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import {Accordion, AccordionDetails, AccordionSummary, CardOverflow, CircularProgress, Stack,} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {Handle, Position} from "reactflow";
import Link from "@mui/joy/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/joy/Chip";
import IconButton from "@mui/joy/IconButton";

// components
import BaseApi from "../../utils/BaseApi";
import RouteConditionCreate from "./RouteNodeComponents/RouteConditionCreate";
import RouteActionCreateCreate from "./RouteNodeComponents/RouteActionCreateCreate";
import RouteActionUpdateCreate from "./RouteNodeComponents/RouteActionUpdateCreate";
import RouteActionCreateUpdate from "./RouteNodeComponents/RouteActionCreateUpdate";
import RouteActionUpdateUpdate from "./RouteNodeComponents/RouteActionUpdateUpdate";
import RouteConditionUpdate from "./RouteNodeComponents/RouteConditionUpdate";
import CommonEditModal from "../Shared/CommonEditModal";
import RouteConditionParams from "./RouteNodeComponents/RouteActionCreateParams";
import RouteActionCreateParams from "./RouteNodeComponents/RouteActionCreateParams";

// icons
import OpenWithIcon from '@mui/icons-material/OpenWith';


class RouteNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stage: props.data.obj,
        };
    }

    async componentDidMount() {
        await this.retrieveActionsCreate();
        await this.retrieveActionsUpdate();
        await this.retrieveConditions();
    }

    async retrieveStage() {
        const url = `route_stage/${this.state.stage.id}/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                stage: response.data
            }
        )
    }

    async retrieveActionsCreate() {
        const url = `route_stage_action_create/`;
        const api = new BaseApi();
        const params = {
            stage: this.state.stage.id
        };
        let response = await api.get(url, params);
        this.setState(
            {
                creates: response.data.results
            }
        )
    }

    async destroyActionCreate(id) {
        const url = `route_stage_action_create/${id}/`;
        const api = new BaseApi();
        await api.delete(url);
        await this.retrieveActionsCreate()
    }

    async retrieveActionsUpdate() {
        const url = `route_stage_action_update/`;
        const api = new BaseApi();
        const params = {
            stage: this.state.stage.id
        };
        let response = await api.get(url, params);
        this.setState(
            {
                updates: response.data.results
            }
        )
    }

    async destroyActionUpdate(id) {
        const url = `route_stage_action_update/${id}/`;
        const api = new BaseApi();
        await api.delete(url);
        await this.retrieveActionsUpdate()
    }

    async retrieveConditions() {
        const url = `route_stage_condition/`;
        const api = new BaseApi();
        const params = {
            stage: this.state.stage.id
        };
        let response = await api.get(url, params);
        this.setState(
            {
                conditions: response.data.results
            }
        )
    }

    async destroyCondition(id) {
        const url = `route_stage_condition/${id}/`;
        const api = new BaseApi();
        await api.delete(url);
        await this.retrieveConditions()
    }

    render() {
        if (!this.state.conditions || !this.state.creates || !this.state.updates) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Card
                    variant={'soft'}
                    color={this.state.stage.next_stages.length===0?'success':this.state.stage.first?'warning':null}
                    sx={{
                        width: 300
                    }}
                >
                    <CardOverflow
                        // variant={"soft"}
                    >
                        <CardContent>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Typography level="body-md">
                                    <Chip
                                        label=""
                                        color={this.state.stage.color}
                                        sx={{
                                            mr: 1
                                        }}
                                    >
                                        {this.state.stage.title}
                                    </Chip>
                                    <CommonEditModal
                                        fields={
                                            [
                                                'title',
                                                'first',
                                                'promotion_logic',
                                                'operator',
                                                'color',
                                                'end_delay',
                                                'is_attach_needed',
                                                'help_text',
                                                'attachment_xtype',
                                            ]
                                        }
                                        baseUrl={'route_stage'}
                                        obj={(() => {
                                            let stage = {...this.state.stage};
                                            if (stage.operator){
                                                stage.operator = stage.operator.id
                                            }
                                            return stage
                                        })()}
                                        callBack={this.retrieveStage.bind(this)}
                                    />
                                </Typography>

                                <IconButton
                                    className="custom-drag-handle"
                                >
                                    <OpenWithIcon/>
                                </IconButton>
                            </Stack>
                        </CardContent >
                    </CardOverflow>
                    <CardContent>
                        <Chip
                            color={'primary'}
                            size={'small'}
                        >
                            {this.state.stage.promotion_logic_human}
                        </Chip>
                        {
                            ['NORMAL', 'TREELIMITED'].includes(this.state.stage.promotion_logic)?
                                <Typography>
                                    Исполнитель: {this.state.stage.operator.title}
                                </Typography>:
                                null
                        }
                        {
                            this.state.stage.is_attach_needed?
                                <Typography>Требуется вложение</Typography>:
                                <Typography>Не требуется вложение</Typography>
                        }
                        <Typography>Срок: {this.state.stage.end_delay} дн.</Typography>
                        <Card
                            variant={'outlined'}
                            sx={{mt: 2}}
                        >
                            <CardContent>
                                <Accordion
                                    // disabled={this.state.conditions.length===0}
                                >
                                    <AccordionSummary>
                                        <Typography level="body-md">
                                            Условия
                                        </Typography>
                                        <Chip
                                            color={this.state.conditions.length?'warning':'primary'}
                                        >
                                            {this.state.conditions.length}
                                        </Chip>
                                        <RouteConditionCreate
                                            stageId={this.state.stage.id}
                                            callBack={this.retrieveConditions.bind(this)}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.state.conditions.map(each => {
                                            return (
                                                <Card
                                                    variant={'soft'}
                                                    sx={{mb: 1}}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Typography level="body-xs">
                                                            {each.entity_human} {each.field} {each.expected_value}
                                                        </Typography>
                                                        <RouteConditionParams
                                                            callBack={this.retrieveConditions.bind(this)}
                                                            obj={each}
                                                        />
                                                        <RouteConditionUpdate
                                                            callBack={this.retrieveConditions.bind(this)}
                                                            obj={each}
                                                        />
                                                        <Link
                                                            level="body-xs"
                                                            color={'danger'}
                                                            onClick={this.destroyCondition.bind(this, each.id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </Link>
                                                    </Stack>
                                                </Card>
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                        <Card
                            variant={'outlined'}
                        >
                            <CardContent>
                                <Accordion
                                    // disabled={this.state.creates.length===0}
                                >
                                    <AccordionSummary>
                                        <Typography level="body-md">
                                            Порождения
                                        </Typography>
                                        <Chip
                                            color={this.state.creates.length?'warning':'primary'}
                                        >
                                            {this.state.creates.length}
                                        </Chip>
                                        <RouteActionCreateCreate
                                            stageId={this.state.stage.id}
                                            callBack={this.retrieveActionsCreate.bind(this)}
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.state.creates.map(each => {
                                            return (
                                                <Card
                                                    variant={'soft'}
                                                    sx={{mb: 1}}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Typography level="body-xs">
                                                            {each.entity_human}
                                                        </Typography>
                                                        <RouteActionCreateParams
                                                            callBack={this.retrieveConditions.bind(this)}
                                                            obj={each}
                                                        />
                                                        <RouteActionCreateUpdate
                                                            callBack={this.retrieveActionsCreate.bind(this)}
                                                            obj={each}
                                                        />
                                                        <Link
                                                            level="body-xs"
                                                            color={'danger'}
                                                            onClick={this.destroyActionCreate.bind(this, each.id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </Link>
                                                    </Stack>
                                                </Card>
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                        <Card
                            variant={'outlined'}
                        >
                            <CardContent>
                                <Accordion
                                    // disabled={this.state.updates.length===0}
                                >
                                    <AccordionSummary>
                                        <Typography level="body-md">
                                            Обновления
                                        </Typography>
                                        <Chip
                                            color={this.state.updates.length?'warning':'primary'}
                                        >
                                            {this.state.updates.length}
                                        </Chip>
                                        <RouteActionUpdateCreate
                                            stageId={this.state.stage.id}
                                            callBack={this.retrieveActionsUpdate.bind(this)}
                                        />

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.state.updates.map(each => {
                                            return (
                                                <Card
                                                    variant={'soft'}
                                                    sx={{mb: 1}}
                                                >
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Typography level="body-xs">
                                                            {each.entity_human} {each.field} {each.new_value}
                                                        </Typography>
                                                        <RouteActionUpdateUpdate
                                                            callBack={this.retrieveActionsUpdate.bind(this)}
                                                            obj={each}
                                                        />
                                                        <Link
                                                            level="body-xs"
                                                            color={'danger'}
                                                            onClick={this.destroyActionUpdate.bind(this, each.id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </Link>
                                                    </Stack>
                                                </Card>
                                            )
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
                <Handle
                    type="target"
                    isConnectable={this.props.isConnectable}
                    position={Position.Left}
                    style={{
                        background: "green",
                        width: 10,
                        height: 10,
                    }}
                />
                <Handle
                    type="source"
                    isConnectable={this.props.isConnectable}
                    position={Position.Right}
                    style={{
                        background: "red",
                        width: 10,
                        height: 10,
                    }}
                    id="blue"
                />
            </>
        )
    }
}

export default RouteNode;
