import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";
import AttachmentsTab from "../Attachment/AttachmentsTab";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FeatureRequestTab from "./FeatureRequestTab";
import FeatureRequestDetailHeader from "./FeatureRequestDetailHeader";


class FeatureRequestDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            featureRequest: null,
        };
    }

    async componentDidMount() {
        await this.retrieveFeatureRequest();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveFeatureRequest();
        }
    }

    async retrieveFeatureRequest() {
        await this.setState(
            {
                featureRequest: null
            }
        );
        const url = `feature_request/${this.props.params.featureRequestId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                featureRequest: response.data
            }
        );
    }

    render() {
        if (!this.state.featureRequest) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'FeatureRequest'}
                appId={this.state.featureRequest.id}
                header={{
                    component: FeatureRequestDetailHeader,
                    componentProps: {
                        featureRequest: this.state.featureRequest,
                    },
                    leftOverflow: {
                        icon: null,
                        title: this.state.featureRequest.work_status_human,
                        color: this.state.featureRequest.work_status_color
                    },
                }}
                defaultTabValue={'info'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: <AccountTreeIcon />,
                        component: <FeatureRequestTab
                            featureRequest={this.state.featureRequest}
                        />,
                    },
                    {
                        title: 'Вложения',
                        value: 'attachment',
                        icon: <AttachFileIcon />,
                        component: <AttachmentsTab
                            appId={this.state.featureRequest.id}
                            appName={'FeatureRequest'}
                        />
                    },
                ]}
            />
        )
    }
}

export default withRouter(FeatureRequestDetail);