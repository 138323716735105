import React from "react";
import {CircularProgress} from "@mui/joy";
import Chip from "@mui/joy/Chip";

// icons
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Tooltip from "@mui/joy/Tooltip";
import BaseApi from "../../utils/BaseApi";


class CommentLike extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liked: this.props.liked,
            likers: null,
        };
    }

    async retriveLikes() {
        const url = `reaction/?comment=${this.props.commentID}`;
        const api = new BaseApi();
        let response = await api.get(url);
        this.setState(
            {
                likers: response.data.results,
            }
        )
    }

    async componentDidMount() {
        await this.retriveLikes();
    }

    async postLike() {
        const url = 'reaction/';
        const data = {
            'comment': this.props.commentID,
            'value': '1'
        }
        const api = new BaseApi();
        await api.post(url, data);
        await this.retriveLikes();
        this.setState(
            {liked: true}
        )
    }

    render() {
        if (!this.state.likers) {
            return (
                <CircularProgress
                    size={'sm'}
                />
            )
        }

        if (this.state.liked === true) {
            return (
                <Tooltip
                    title={
                        this.state.likers.map((each) => {return(`${each.owner.first_name} ${each.owner.last_name}`)}).join(', ')
                    }
                    variant="soft"
                >
                    <Chip
                        size={'sm'}
                        startDecorator={
                            <ThumbUpIcon
                                color={'success'}
                                fontSize='sm'
                            />
                        }
                        sx={{
                            mr: 2,
                        }}
                    >
                        {this.state.likers.length}
                    </Chip>

                </Tooltip>
            )
        }

        return (
            <Tooltip
                    title={
                        this.state.likers.map((each) => {return(`${each.first_name} ${each.last_name}`)}).join(', ')
                    }
                    variant="soft"
                >
                    <Chip
                        size={'sm'}
                        startDecorator={
                            <ThumbUpIcon
                                fontSize='sm'
                            />
                        }
                        sx={{
                            mr: 2,
                        }}
                        onClick={this.postLike.bind(this)}
                    >
                        {this.state.likers.length}
                    </Chip>
                </Tooltip>
        )
    }
}

export default CommentLike