import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";

import UserSubscriberContext from "../../context/UserSubscriberContext";

// components
import RouteStateConditionsTable from "./RouteStateConditionsTable";
import RouteStateCreate from "./RouteStateCreate";
import RouteStateCard from "./RouteStateCard";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import AttachmentListItem from "../Attachment/AttachmentListItem";
import RouteGraph from "./RouteGraph";

// icons
import WebAssetIcon from '@mui/icons-material/WebAsset';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {camelToSnakeCase} from "../Notification/NotificationLink";


class RouteDetail extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            route: Object.hasOwn(this.props, 'route')?this.props.route:null,
            states: Object.hasOwn(this.props, 'route')?this.props.route.states:null,
            modalOpen: false,
            owner: null,
        };
    }

    async componentDidMount() {
        if (!this.state.route || !this.state.states){
            await this.retrieveRoute();
        }
        else {
            let systemOwner = await this.retrieveSystemOwner(
                this.state.route.entity,
                this.state.route.entity_id
            );
            await this.setState(
                {
                    systemOwner: systemOwner,
                }
            )
        }
    }

    async retrieveSystemOwner(entity, id) {
        const url = `${camelToSnakeCase(entity)}/${id}/system_owner/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        if (response.status === 200){
            return response.data
        }
        return null
    }

    async retrieveRoute() {
        this.setState(
            {
                route: null,
                states: null,
            }
        );
        let url = '';
        if (Object.hasOwn(this.props, 'componentObj')){
            url = `route/${this.props.componentObj.route.id}/`;
        }
        else {
            url = `route/${this.props.route.id}/`;
        }
        const api = new BaseApi();
        let response = await api.get(url, {});
        let systemOwner = await this.retrieveSystemOwner(
            response.data.entity,
            response.data.entity_id,
        );
        await this.setState(
            {
                route: response.data,
                states: response.data.states,
                systemOwner: systemOwner,
            }
        );
    }

    setModalOpenTrue(){
        this.setState(
            {
                modalOpen: true,
            }
        );
    }

    async setModalOpenFalse() {
        await this.setState(
            {
                modalOpen: false,
            }
        )
        if(typeof this.props.callBack == 'function') {
            await this.props.callBack();
        }
    }

    render() {
        if (!this.state.route){
            return (
                <CircularProgress/>
            )
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    height: '100%'
                                }}
                            >
                                <Grid
                                    md={8}
                                    sx={{
                                        height: '100%',
                                        overflowY: 'scroll'
                                    }}
                                >
                                    <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                <AccountTreeIcon />
                                                <Typography level={'h3'}>
                                                    {this.state.route.title}
                                                </Typography>
                                                <RouteGraph
                                                    routeId={this.state.route.id}
                                                />
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={2}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                <Typography
                                                    level={'h4'}
                                                >
                                                    {this.state.route.completed?'Последняя стадия':'Текущая стадия'}
                                                </Typography>
                                                <Chip
                                                    variant="soft"
                                                    color={this.state.states[0].stage.color}
                                                    sx={{ml: 1}}
                                                >
                                                    {this.state.states[0].stage.title}
                                                </Chip>
                                            </Stack>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid xs={12} md={4}>
                                                    <Typography level={'body2'} sx={{mb: 1}}>Исполнитель:</Typography>
                                                    <ProfileHorizontalByProps
                                                        user={this.state.states[0].operator.user}
                                                    />
                                                </Grid>
                                                <Grid xs={12} md={4}>
                                                    <Typography level={'body2'} sx={{mb: 1}}>Дней в работе:</Typography>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Chip
                                                            color={this.state.states[0].delay[1]?'success':'danger'}
                                                        >
                                                            {this.state.states[0].delay[0]}
                                                        </Chip>
                                                        <Typography level={'body2'} sx={{mb: 1}}>из</Typography>
                                                        <Chip
                                                            color={'primary'}
                                                        >
                                                            {this.state.states[0].stage.end_delay}
                                                        </Chip>
                                                    </Stack>
                                                </Grid>
                                                <Grid xs={12} md={4}>
                                                    <Typography level={'body2'} sx={{mb: 1}}>Создана:</Typography>
                                                    <Typography level={'body1'}>
                                                        {this.state.states[0].created_on}
                                                    </Typography>
                                                </Grid>
                                                {this.state.route.completed?
                                                    <>
                                                        <Grid xs={12} md={12}>
                                                            <Typography level={'body2'} sx={{mb: 1}}>Вложение:</Typography>
                                                            {this.state.states[0].attachment?
                                                                <Card
                                                                    variant={'soft'}
                                                                >
                                                                    <AttachmentListItem
                                                                        attachment={this.state.states[0].attachment}
                                                                    />
                                                                </Card>
                                                                :
                                                                '-'
                                                            }
                                                        </Grid>
                                                        <Grid xs={12} md={12}>
                                                            <Typography level={'body2'} sx={{mb: 1}}>Комментарий:</Typography>
                                                            <Typography level={'body1'} sx={{mb: 1}}>
                                                                {this.state.states[0].note?this.state.states[0].note:'-'}
                                                            </Typography>
                                                        </Grid>
                                                    </>:
                                                    null
                                                }
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                    {!this.state.route.completed?
                                        <>
                                            <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Typography level={'h3'}>
                                                Подсказка
                                            </Typography>
                                            {this.state.states[0].stage.help_text?
                                                <div dangerouslySetInnerHTML={{__html: this.state.states[0].stage.help_text}} />:
                                                <Typography level={'body-md'}>Не указана</Typography>
                                            }
                                        </CardContent>
                                    </Card>
                                    <Card
                                        sx={{mb: 1}}
                                        variant={'soft'}
                                    >
                                        <CardContent>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                Условия стадии
                                            </Typography>
                                            <RouteStateConditionsTable
                                                routeStateId={this.state.states[0].id}
                                            />
                                        </CardContent>
                                    </Card>
                                            {
                                                this.context.userObject.id === this.state.states[0].operator.user.id ?
                                                    <Card
                                                        sx={{mb: 1}}
                                                        variant={'soft'}
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'h3'}
                                                                sx={{
                                                                    mb: 2
                                                                }}
                                                            >
                                                                Продвижение по процессу
                                                            </Typography>
                                                            <RouteStateCreate
                                                                routeState={this.state.states[0]}
                                                                entity={this.state.route.entity}
                                                                entity_id={this.state.route.entity_id}
                                                                systemOwner={this.state.systemOwner}
                                                                callBack={this.retrieveRoute.bind(this)}
                                                            />
                                                        </CardContent>
                                                    </Card> :
                                                    <Card
                                                        color={'warning'}
                                                        variant="soft"
                                                    >
                                                        <CardContent>
                                                            <Typography
                                                                level={'body-md'}
                                                            >
                                                                Вы не являетесь исполнителем этой стадии
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                            }
                                        </>:
                                        <Card
                                            color={'success'}
                                            variant="soft"
                                        >
                                            <CardContent>
                                                <Typography
                                                    level={'body-md'}
                                                >
                                                    Маршрут завершен
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    }
                                </Grid>
                                <Grid
                                    md={4}
                                    sx={{
                                        height: '100%',
                                        overflowY: 'scroll'
                                    }}
                                >
                                    <Card
                                        variant="soft"
                                        sx={{
                                            overflowY: 'scroll',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                level={'h3'}
                                                sx={{
                                                    mb: 2
                                                }}
                                            >
                                                История стадий
                                            </Typography>
                                            {this.state.states.map(
                                                (each, index) => {
                                                    if (index === 0){
                                                        return null
                                                    }
                                                    return (
                                                        <RouteStateCard
                                                            state={each}
                                                            key={'route-state-card-'+each.id}
                                                        />
                                                    )
                                                }
                                                )
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default RouteDetail
