import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {LinearProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";


class OpportunityPaymentCert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment_cert: null,
        }
    }

    async retrieveOpportunityPaymentCert() {
        const url = 'opportunity/' + this.props.opportunityID + '/payment_cert/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        if (response.status === 200){
            this.setState(
                {
                    payment_cert: response.data
                }
            );
        }
    }

    async componentDidMount() {
        await this.retrieveOpportunityPaymentCert();
    }

    render() {
        if (!this.state.payment_cert) {
            return (<></>)
        }
        return(
            <div>
                <Typography level={'body2'}>Платежи и акты</Typography>
                <div className="row">
                    <div className="col-12">
                        <Typography level={'body3'} sx={{mt: 1}}>П: {this.state.payment_cert.payment_summ.toLocaleString('ru-RU')} Руб. </Typography>
                        <LinearProgress determinate value={this.state.payment_cert.payment_percent}  size="lg" variant="outlined" color="success"/>
                        <Typography level={'body3'} sx={{mt: 1}}>А: {this.state.payment_cert.cert_summ.toLocaleString('ru-Ru')} Руб.</Typography>
                        <LinearProgress determinate value={this.state.payment_cert.cert_percent}  size="lg" variant="outlined" color="success"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default OpportunityPaymentCert;