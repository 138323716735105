import * as React from 'react';

import {
    ThemeProvider as MaterialThemeProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/joy/Box';

import { Outlet } from 'react-router-dom';

import { AuthProvider } from '../../context/AuthContext';
import ScrollToTop from "./ScrollToTop";
import {UserSubscriberProvider,} from "../../context/UserSubscriberContext";
import BaseHeader from "../Header/BaseHeader";
import BaseSidebar from "../Sidebar/BaseSidebar";
import {materialTheme, joyTheme} from "../../theme";
import Sheet from "@mui/joy/Sheet";


export default function Root(){
    return (
        <AuthProvider>
            <UserSubscriberProvider>
                <ScrollToTop />
                <MaterialThemeProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
                    <JoyCssVarsProvider theme={joyTheme}>
                        <CssBaseline enableColorScheme />
                        <Sheet
                            id={'main'}
                            sx={
                            {
                                display: 'flex',
                                minHeight: '100dvh',
                                maxHeight: '100dvh',
                                overflow: 'auto'
                            }
                        }>
                            <BaseHeader />
                            <BaseSidebar />
                            <Box
                                component="main"
                                className="MainContent"
                                sx={(theme) => ({
                                    px: {
                                        xs: 2,
                                        md: 3,
                                    },
                                    pt: {
                                        xs: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                        sm: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                        md: `calc(${theme.spacing(2)} + var(--Header-height))`,
                                        lg: 3,
                                    },
                                    pb: {
                                        xs: 2,
                                        sm: 2,
                                        md: 3,
                                    },
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minWidth: 0,
                                    height: '100dvh',
                                    gap: 1,
                                })}
                            >
                                <Outlet />
                            </Box>
                        </Sheet>
                    </JoyCssVarsProvider>
                </MaterialThemeProvider>
            </UserSubscriberProvider>
        </AuthProvider>
    )
}
