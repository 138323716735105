import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

// components
import CommonForm from "../Shared/CommonForm";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';


class IdeaCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
            success: false,
        };
    }

    async componentDidMount(){
        await this.retrieveFields();
    }

    async retrieveFields(){
        const url = `idea_node/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                formFields: response.data
            }
        );
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.parent = this.props.parent;
        if (this.props.x) {
            formDataObj.x_coordinate = this.props.x + 300;
        }
        if (this.props.y) {
            formDataObj.y_coordinate = this.props.y + 300;
        }
        const url = `idea_node/?root=${this.props.root}`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.setModalOpenFalse();
        }
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    render() {
        if (!this.state.formFields){
            return (<CircularProgress/>)
        }
        return (
            <Box>
                <Button
                    size={'sm'}
                    color="success"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon size={'sm'}/>
                    {
                        this.props.title?
                            this.props.title:
                            'Добавить идею'
                    }
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Добавление идеи
                            </Typography>
                            <CommonForm
                                fields={this.state.formFields}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default IdeaCreate
