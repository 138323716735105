import React from 'react';
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// components
import BaseApi from "../../utils/BaseApi";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import BoltIcon from '@mui/icons-material/Bolt'


class EstimationRevisionSetCurrent extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            setCurrentPermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveSetCurrentPermission();
    }


    async retrieveSetCurrentPermission() {
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'set_current',
            this.context.subscriberObject
        );
        this.setState(
            {
                setCurrentPermission: p
            }
        );
    }

    async setCurrentRevision() {
        const url = `estimation_revision/${this.props.revision.id}/set_current/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        if (response.status === 200){
            await this.props.callBack();
        }
    }

    render() {
        return(
            <>
                {
                    this.state.setCurrentPermission > 0 ?
                        <Box
                            sx={{
                                display: 'inline'
                            }}
                        >
                            <IconButton
                                size={'sm'}
                                color="neutral"
                                onClick={this.setCurrentRevision.bind(this)}
                            >
                                <BoltIcon />
                            </IconButton>
                        </Box> :
                        null
                }
            </>
        )
    }
}

export default EstimationRevisionSetCurrent