import React from "react";
import {CircularProgress, Stack, TabList, TabPanel, Tabs} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Tab from "@mui/joy/Tab";
import AttachmentCategoryList from "./AttachmentCategoryList";
import Chip from "@mui/joy/Chip";

class AttachmentsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: null,
            fsDirectory: null
        };
    }

    async componentDidMount() {
        await this.updateAttachments();
        await this.retrieveCategories();
    }

    async updateAttachments() {
        if (this.props.appName === 'Opportunity'){
            const url = `opportunity/${this.props.appId}/update_attachments`;
            const api = new BaseApi();
            let response = await api.get(url, {});
            if (response.status === 200) {
                this.setState(
                    {
                        fsDirectory: response.data.fs_directory
                    }
                )
            }
        }
    }

    async retrieveCategories() {
        const url = `attachment/subscriber_xtypes`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let categories = []
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])){
                categories.push(category);
            }
        }
        this.setState(
            {
                categories: categories,
            }
        );
    }

    render(){
        if (!this.state.categories) {
            return(
                <CircularProgress/>
            )
        }
        return(
            <Stack
                spacing={2}
                sx={{
                    width: '100%',
                }}
            >
                {
                    this.props.appName === 'Opportunity'?
                        <Chip
                            color={'warning'}
                        >
                            label={`Папка FS: \\\\fs\\crm-prod\\${this.state.fsDirectory}\\`}
                        </Chip>:
                        null
                }
                <Tabs
                    variant='soft'
                    orientation={'vertical'}
                    aria-label='Placement indicator tabs'
                    sx={{
                        minWidth: 200,
                    }}
                    defaultValue={this.state.categories[0][0]}
                >
                    <TabList
                        disableUnderline
                    >
                        {this.state.categories.map(each => {
                            return (
                                <Tab
                                    disableIndicator
                                    value={each[0]}
                                >
                                    {each[1]}
                                </Tab>
                            )
                        })}
                    </TabList>
                    {this.state.categories.map((each) => {
                        return (
                            <TabPanel
                                value={each[0]}
                                variant={'soft'}
                                sx={{
                                    pt: 0,
                                    borderRadius: 'md',
                                    overflowY: 'scroll',

                                }}
                            >
                                <AttachmentCategoryList
                                    appId={this.props.appId}
                                    appName={this.props.appName}
                                    category={each[0]}
                                    fsDirectory={this.state.fsDirectory}
                                />
                            </TabPanel>
                        )
                    })}
                </Tabs>
            </Stack>
        )
    }
}

export default AttachmentsTab