import React from 'react';

import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import QualificatorApprove from "./QualificatorApprove";
import QualificatorUpdate from "./QualificatorUpdate";

// components

class QualificatorCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qualificator: null,
        };
    }

    async componentDidMount() {
        await this.retrieveQualificator();
    }

    async retrieveQualificator() {
        this.setState(
            {
                qualificator: null
            }
        );
        const url = `qualificator/${this.props.qualificatorId}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        this.setState(
            {
                qualificator: response.data
            }
        );
    }

    render() {
        if (!this.state.qualificator) {
            return (<CircularProgress/>)
        }
        return(
            <Stack>
                <Typography>
                    <strong>
                        Отрасль:&nbsp;
                    </strong>
                    {this.state.qualificator.industry_focus.map(each => {return(<>{each.title}, </>)})}
                </Typography>
                <Typography>
                    <strong>
                        Тип физики:&nbsp;
                    </strong>
                    {this.state.qualificator.physic_task_type.map(each => {return(<>{each.title}, </>)})}
                </Typography>
                <Typography>
                    <strong>
                        Уровень задачи:&nbsp;
                    </strong>
                    {this.state.qualificator.task_level.title}
                </Typography>
                <Typography>
                    <strong>
                        Причина запроса:&nbsp;
                    </strong>
                    {this.state.qualificator.calculations_purpose.map(each => {return(<>{each.title}, </>)})}
                </Typography>
                <Typography>
                    <strong>
                        Форма отчета:&nbsp;
                    </strong>
                    {this.state.qualificator.report_form.map(each => {return(<>{each.title}, </>)})}
                </Typography>
                <Typography>
                    <strong>
                        Дополнительные опции:&nbsp;
                    </strong>
                    {this.state.qualificator.extra_terms.map(each => {return(<>{each.title}, </>)})}
                </Typography>
                <QualificatorApprove
                    qualificator={this.state.qualificator}
                    callBack={this.retrieveQualificator.bind(this)}
                />
                <QualificatorUpdate
                    qualificator={this.state.qualificator}
                    callBack={this.retrieveQualificator.bind(this)}
                />
            </Stack>
        )
    }
}

export default QualificatorCard