import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";
import Typography from "@mui/joy/Typography";


class HomePageItemCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            success: false,
        };
        this.formFields = [
            {
                'name': 'module',
                'label': 'Модуль',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': null,
                'type': 'select',
                'options': Object.entries(this.props.modules).map((each) => {return(
                    {'value': each[0], 'label': each[1].title}
                )})
            },
            {
                'name': 'size',
                'label': 'Размер',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'select',
                'options': [
                    {
                        value: 1,
                        label: 'Половина ширины'
                    },
                    {
                        value: 2,
                        label: 'Полная ширина'
                    },
                ]
            },
            {
                type: 'submit',
                label: 'Добавить'
            }
        ];
    }

    async componentDidMount(){
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        let item = {
            id: formDataObj.module,
            size: parseInt(formDataObj.size),
        };
        await this.props.callBack(item);
        this.setState(
            {
                success: true
            }
        );
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
                success: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.success?
                                    <>
                                        <Typography>
                                            Модуль добавлен успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Добавление модуля главной страницы
                                        </Typography>
                                        <CommonForm
                                            fields={this.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default HomePageItemCreate
