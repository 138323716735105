import React from 'react';

import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import {Stack} from "@mui/joy";

// components
import DeliveryDetail from "./DeliveryDetail";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";
import DeliveryControlledEntityModal from "./DeliveryControlledEntityModal";


class DeliveryCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            dcePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveDcePermission();
    }

    async retrieveDcePermission() {
        let p = await retrievePermission(
            'DeliveryControlledEntityViewSet',
            'create',
            this.context.subscriberObject
        )
        this.setState(
            {
                dcePermission: p,
            }
        )
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box
                            sx={{p: 1, width: '100%'}}
                        >
                            <Chip
                                color={'primary'}
                                sx={{mr: 2}}
                            >
                                {this.props.delivery.xtype_human}
                            </Chip>
                            <Typography
                                level={'body2'}
                                sx={{mr: 2, display: 'inline-flex'}}
                            >
                                {this.props.delivery.title}
                            </Typography>
                            <Chip
                                color={this.props.delivery.states[0].stage.color}
                            >
                                {this.props.delivery.states[0].stage.title}
                            </Chip>
                        </Box>
                        {this.state.dcePermission > 0?
                            <DeliveryControlledEntityModal
                                delivery={this.props.delivery}
                            />:
                            null
                        }
                        <DeliveryDetail
                            delivery={this.props.delivery}
                            callBack={this.props.callBack}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default DeliveryCard