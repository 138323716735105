import React from "react";

import UserSubscriberContext from "../../context/UserSubscriberContext";
import Header from "./Header";


class BaseHeader extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {
    }

    render() {
        if (this.context.subscriberObject.external){
            return null
        }
        if (!this.context){
            return null
        }
        else {
            return <Header />
        }
    }
}

export default BaseHeader
