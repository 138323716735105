import React from 'react';


import {CardOverflow, CircularProgress} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";

// components
import TaskCardInner from "./TaskCardInner";


class TaskCard extends React.Component {
    render() {
        if (!this.props.task) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={{mb: 1}}
            >
                <CardOverflow
                    variant='soft'
                    color={this.props.task.work_status_color}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {this.props.task.work_status_human}
                </CardOverflow>
                <CardContent>
                    <TaskCardInner
                        task={this.props.task}
                    />
                </CardContent>
            </Card>
        )
    }
}


export default TaskCard;
