import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import KpiReport from "./KpiReport";


class KpiSubscriberList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kpis: [],
        };
    }

    async componentDidMount() {
        await this.retrieveKpis();
    }

    async retrieveKpis() {
        const url = 'kpi/subscriber_kpis/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {
                subscriber: this.props.subscriber.id,
            }
        );
        this.setState(
            {
                kpis: response.data,
            }
        );
    }

    render() {
        if (!this.state.kpis) {
            return (<CircularProgress/>)
        }
        return (
            <>
                {this.state.kpis.map((kpi) => {
                    return(
                        <KpiReport
                            kpi={kpi}
                            subscriber={this.props.subscriber.id}
                        />
                    )
                })}
            </>
        )
    }
}

export default KpiSubscriberList