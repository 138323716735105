import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ApprovalIcon from '@mui/icons-material/Approval';
import CalculateIcon from '@mui/icons-material/Calculate';

class FinancialSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={
                    [
                        {
                            title: 'Финансовое управление',
                            items: [
                                {
                                    title: 'Входящие платежи',
                                    link: '/financial/payment/',
                                    icon: <CurrencyRubleIcon />,
                                },
                                {
                                    title: 'Акты',
                                    link: '/financial/certificate/',
                                    icon: <ApprovalIcon />,
                                },
                            ],
                        },
                        {
                            title: 'Анализ',
                            items: [
                                {
                                    title: 'Вычислитель',
                                    link: '/financial/calculator/',
                                    icon: <CalculateIcon />,
                                },
                                {
                                    title: 'Статистика сделок',
                                    link: '/financial/opportunity_financial_report/',
                                    icon: <QueryStatsIcon />,
                                },
                            ],
                        },
                        {
                        title: 'Моделирование',
                        items: [
                            {
                                title: 'Фин. модель',
                                link: '/financial/model/',
                                icon: <CalculateIcon />,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default FinancialSidebar;
