import React from "react";
import Button from "@mui/joy/Button";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CommonForm from "./CommonForm";

//icons
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';


class CommonFilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    discardFilters() {
        this.props.callBack({});
    }

    processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        this.props.callBack(formDataObj);
        this.setState({
            modalOpen: false,
        })
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    sx={{mt: 2, mr: 2}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <TuneIcon sx={{mr: 1}}/> Фильтры
                </Button>
                <Button
                    size={'sm'}
                    color="warning"
                    sx={{mt: 2, mr: 2}}
                    onClick={this.discardFilters.bind(this)}
                >
                    <CloseIcon sx={{mr: 1}}/> Сбросить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Фильтры
                            </Typography>
                            <CommonForm
                                fields={
                                    this.props.filterFields.concat(
                                        [
                                            {
                                                type: 'submit',
                                                label: 'Применить',
                                            },
                                        ]
                                    )
                                }
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default CommonFilterModal