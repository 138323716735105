import * as React from 'react';

import {useColorScheme as useJoyColorScheme} from '@mui/joy/styles';
import {useColorScheme as useMaterialColorScheme} from '@mui/material/styles';
import {DarkMode, LightMode} from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";

const ColorSchemeToggle = () => {
    const {mode, setMode: setMaterialMode} = useMaterialColorScheme();
    const {setMode: setJoyMode} = useJoyColorScheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
        setMounted(true);
    }, []);
    if (!mounted) {
        return null;
    }
    return (
        <IconButton
            onClick={() => {
                setMaterialMode(mode === 'dark' ? 'light' : 'dark');
                setJoyMode(mode === 'dark' ? 'light' : 'dark');
            }}
        >
            {mode === 'dark' ? <DarkMode/> : <LightMode/>}
        </IconButton>
    );
};

export default ColorSchemeToggle
