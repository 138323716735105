import React from 'react';

import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";
import {CircularProgress} from "@mui/joy";


class QualificatorCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: null
        };
    }

    async componentDidMount() {
        await this.retrieveFormFields();
    }

    async retrieveFormFields() {
        const url = `qualificator/create_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        this.setState(
            {
                formFields: response.data
            }
        )
    }

    async processForm(form){
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.industry_focus = formDataObj.industry_focus.split(',');
        formDataObj.physic_task_type = formDataObj.physic_task_type.split(',');
        formDataObj.calculations_purpose = formDataObj.calculations_purpose.split(',');
        formDataObj.report_form = formDataObj.report_form.split(',');
        formDataObj.extra_terms = formDataObj.extra_terms.split(',');
        let url = `qualificator/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj,
        );
        if (response.status === 201){
            let url = `opportunity/${this.props.opportunity.id}/`
            let opp_response = await api.patch(
                url,
                {
                    qualificator: response.data.id,
                }
            );
            if (opp_response.status === 200){
                this.props.callBack(opp_response.data.qualificator);
            }
        }
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress/>)
        }
        return(
            <CommonForm
                processForm={this.processForm.bind(this)}
                fields={this.state.formFields}
            />
        )
    }
}

export default QualificatorCreate;
