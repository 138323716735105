import React from "react";
import {CardOverflow, CircularProgress} from "@mui/joy";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import dayjs from "dayjs";
import Chip from "@mui/joy/Chip";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";


// components
import OpportunityPaymentCert from "../Opportunity/OpportunityPaymentCert";
import BaseApi from "../../utils/BaseApi";
import withNavigate from "../../utils/withNavigate";


class OpportunitySearchCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: null,
            // comments: null,
            // deliveries: null,
            my_roles: null,
        }
    }

    async retrieveOpportunity() {
        const url = 'opportunity/' + this.props.opportunityId + '/card/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState({
            opportunity: response.data.opportunity,
            comments: response.data.comments,
            delivery_states: response.data.delivery_states,
            my_roles: response.data.my_roles,
        });
    }

    async componentDidMount() {
        await this.retrieveOpportunity();
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        'm': 2,
                    }
                }
            >
                <CardOverflow
                    variant='soft'
                    color={'primary'}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    Сделка
                </CardOverflow>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={4} onClick={() => {this.props.callBack()}}>
                            <Typography level={'body2'} sx={{mb: 1}}>Название:</Typography>
                            <Link onClick={() => this.props.navigate(`/opportunity/detail/${this.state.opportunity.id}`)}>
                                {this.state.opportunity.short_name}
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4} onClick={() => {this.props.callBack()}}>
                            <Typography level={'body2'} sx={{mb: 1}}>Компания:</Typography>
                            <Link
                                onClick={() => this.props.navigate(`/account/detail/${this.state.opportunity.account.id}`)}
                            >
                                {this.state.opportunity.account.nameshort}
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography level={'body2'} sx={{mb: 1}}>Дедлайн:</Typography>
                            {dayjs(this.state.opportunity.project_date).format('DD.MM.YYYY')}
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography level={'body2'} sx={{mb: 1}}>Руководители:</Typography>
                            {this.state.opportunity.accountables.map(
                                row => {
                                    return (
                                        <Chip
                                            color={'warning'}
                                            key={'accountable-chip-'+row.id}
                                        >
                                            {row.subscriber.user.first_name} {row.subscriber.user.last_name}
                                        </Chip>
                                    )
                                })}
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography level={'body2'} sx={{mb: 1}}>Мои роли:</Typography>
                            {this.state.my_roles.map(
                                row => {
                                    return (
                                        <Chip
                                            color={row.color}
                                            sx={{mr: 1, mb: 1}}
                                            key={'my-role-chip-'+row.id}
                                        >
                                            {row.role_human.slice(0, 3)}
                                        </Chip>
                                    )
                                })}
                        </Grid>
                        <Grid xs={12} md={4}>
                            <OpportunityPaymentCert opportunityID={this.props.opportunityId} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default withNavigate(OpportunitySearchCard)