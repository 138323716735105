import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Stack} from "@mui/joy";
import { BarChart } from '@mui/x-charts/BarChart';
import Typography from "@mui/joy/Typography";


class EstimationRevisionChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartData: null,
        };
    }

    async componentDidMount() {
        await this.retrieveChartData();
    }

    async retrieveChartData(){
        const url = `estimation_revision/${this.props.revision.id}/chart`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        this.setState(
            {
                chartData: response.data
            }
        );
    }

    render() {
        if (!this.state.chartData) {
            return (<CircularProgress/>)
        }
        if (this.state.chartData.estimation_chart_labels.length === 0) {
            return (
                <Typography level={'body-md'}>
                    Не возможно выполнить расчет ожидаемой трудоемкости
                </Typography>
            )
        }
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <BarChart
                    key={'EstimationChart' + this.props.revision.id}
                    height={200}
                    series={
                        [
                            {
                                data: this.state.chartData.estimation_chart_values,
                                label: 'Вероятность трудоемскости',
                                type: 'bar'
                            }
                        ]
                    }
                    xAxis={
                        [
                            {
                                scaleType: 'band',
                                data: this.state.chartData.estimation_chart_labels
                            }
                        ]
                    }
                />
                <BarChart
                    key={'EstimationTermChart' + this.props.revision.id}
                    height={200}
                    series={
                        [
                            {
                                data: this.state.chartData.estimation_chart_values_term,
                                label: 'Вероятность срока',
                                type: 'bar',
                                color: '#59a14f',
                            }
                        ]
                    }
                    xAxis={
                        [
                            {
                                scaleType: 'band',
                                data: this.state.chartData.estimation_chart_labels_term
                            }
                        ]
                    }
                />
            </Stack>
        )
    }
}

export default EstimationRevisionChart