import React from 'react';
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import {Stack} from "@mui/joy";
import Chip from "@mui/joy/Chip";
import Typography from "@mui/joy/Typography";
import CommonEditModal from "../Shared/CommonEditModal";


class CommunicationCard extends React.Component {
    render() {
        return(
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Chip
                            color={'primary'}
                        >
                            {this.props.communication.type_human}
                        </Chip>
                        <Chip
                            color={'primary'}
                        >
                            {this.props.communication.subtype_human}
                        </Chip>
                        <Typography
                            level={'body-md'}
                        >
                            {this.props.communication.content}
                        </Typography>
                        <CommonEditModal
                            fields={
                                [
                                    'type',
                                    'subtype',
                                    'content'
                                ]
                            }
                            baseUrl={'communication'}
                            obj={this.props.communication}
                            callBack={this.props.callBack}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default CommunicationCard