import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";
import Typography from "@mui/joy/Typography";


class FinancialModelSalaryCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
        this.formFields = [
            {
                'name': 'title',
                'label': 'Заголовок',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'count',
                'label': 'Количество раз',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'number',
            },
            {
                'name': 'value',
                'label': 'Значение',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 1,
                'type': 'number',
            },
            {
                type: 'submit',
                label: 'Добавить'
            }
        ]
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Добавление инженерного работника
                            </Typography>
                            <CommonForm
                                fields={this.formFields}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default FinancialModelSalaryCreate
