import React from 'react';
import Box from "@mui/joy/Box";

class FeatureRequestTab extends React.Component {
    render() {
        return(
            <Box>
                <div dangerouslySetInnerHTML={{__html: this.props.featureRequest.desc}} />
            </Box>
        )
    }
}

export default FeatureRequestTab