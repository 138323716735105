import React from "react";
import {CardOverflow, TabList, TabPanel, Tabs} from "@mui/joy";
import CommentSidebar from "../Comment/CommentSidebar";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Tab, {tabClasses} from "@mui/joy/Tab";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";


class CommonModalInner extends React.Component {
    render() {
        return (
            <Box
                sx={{
                    overflowY: 'scroll',
                    width: 'calc(80vw)',
                    pr: 2
                }}
            >
                <Card
                    orientation={'horizontal'}
                >
                    <CardOverflow
                        variant='soft'
                        color={this.props.headerOverflowColor}
                        sx={{
                            px: 0.2,
                            writingMode: 'vertical-lr',
                            transform: 'rotate(180deg)',
                            textAlign: 'center',
                            fontSize: 'xs',
                            fontWeight: 'xl',
                            letterSpacing: '1px',
                            textTransform: 'uppercase',
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        {this.props.headerOverflowTitle}
                    </CardOverflow>
                    <CardContent>
                        {this.props.headerComponent}
                    </CardContent>
                </Card>
                <Tabs
                    // variant='soft'
                    orientation={'horizontal'}
                    aria-label='Placement indicator tabs'
                    defaultValue={this.props.defaultTabValue}
                    sx={{
                        gridColumn: '1/-1',
                        flexDirection: 'column',
                        borderRadius: 'var(--joy-radius-md);',
                        mt: 1,
                        minHeight: '250px',
                        // height: 'calc(100vh - 250px)',
                    }}
                >
                    <TabList
                        disableUnderline
                        sx={{
                            p: 0.5,
                            gap: 0.5,
                            borderRadius: 'md',
                            bgcolor: 'background.level1',
                            [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                boxShadow: 'sm', bgcolor: 'background.surface',
                            },
                        }}
                    >
                        {this.props.tabComponents.filter((each) => !each.hidden).map(each => {
                            return (
                                <Tab
                                    disableIndicator
                                    value={each.value}
                                >
                                    <ListItemDecorator>
                                        {each.icon}
                                    </ListItemDecorator>
                                    {each.title}
                                </Tab>
                            )
                        })}
                    </TabList>
                    {this.props.tabComponents.filter((each) => !each.hidden).map(each => {
                        return (
                            <TabPanel
                                value={each.value}
                                variant={'soft'}
                                sx={{
                                    mt: 1, borderRadius: 'md', overflow: 'auto',
                                }}
                            >
                                {each.component}
                            </TabPanel>
                        )
                    })}
                </Tabs>
                <Divider
                    sx={{
                        mt: 2,
                        mb: 2,
                    }}
                />
                <CommentSidebar appName={this.props.appName} appId={this.props.appId}/>
            </Box>
        )
    }
}

export default CommonModalInner