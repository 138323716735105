import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import PermissionViewSet from "./PermissionViewSet";



class PermissionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
            subscriberTypes: null,
        };
    }

    async componentDidMount() {
        await this.retrieveReport();
        await this.retrieveSubscriberTypes();
    }

    async retrieveSubscriberTypes() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `subscriber_type/`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        let subscriberTypes = {};
        for (let subscriberType of response.data.results) {
                subscriberTypes[subscriberType.id] = subscriberType.title
        }
        this.setState(
            {
                subscriberTypes: subscriberTypes
            }
        );
    }

    async retrieveReport() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `api_permission/report`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        this.setState(
            {
                report: response.data
            }
        );
    }

    render(){
        if (!this.state.report){
            return (<CircularProgress />)
        }
        if (!this.state.subscriberTypes){
            return (<CircularProgress />)
        }
        return(
            <>
                <Typography level={'h2'}>
                    Права API
                </Typography>
                {
                    this.state.report.map(each => {
                        return(
                            <PermissionViewSet
                                viewset={each}
                                subscriberTypes={this.state.subscriberTypes}
                            />
                        )
                    })
                }
            </>
        )
    }
}

export default PermissionReport