import React from "react";

import {CircularProgress} from "@mui/joy";

// components
import withRouter from "../../utils/withRouter";
import BaseApi from "../../utils/BaseApi";

// icons


class CourseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            course: null,
        };
    }

    async componentDidMount() {
        await this.retrieveCourse();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveCourse();
        }
    }

    async retrieveCourse() {
        this.setState(
            {
                course: null
            }
        );
        const url = `course/${this.props.params.courseId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                course: response.data
            }
        );
    }

    render() {
        if (!this.state.course) {
            return (<CircularProgress/>)
        }
        return (
            <></>
        )
    }
}

export default withRouter(CourseDetail);
