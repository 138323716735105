import React from 'react';

import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import {Stack} from "@mui/joy";

// components
import RouteDetail from "./RouteDetail";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import retrievePermission from "../../utils/retrievePermission";
// import RouteControlledEntityModal from "./RouteControlledEntityModal";


class RouteCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            dcePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveDcePermission();
    }

    async retrieveDcePermission() {
        let p = await retrievePermission(
            'RouteControlledEntityViewSet',
            'create',
            this.context.subscriberObject
        )
        await this.setState(
            {
                dcePermission: p,
            }
        )
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box
                            sx={{p: 1, width: '100%'}}
                        >
                            <Chip
                                color={'primary'}
                                sx={{mr: 2}}
                            >
                                {this.props.route.entity_human}
                            </Chip>
                            <Typography
                                level={'body2'}
                                sx={{mr: 2, display: 'inline-flex'}}
                            >
                                {this.props.route.title}
                            </Typography>
                            <Chip
                                sx={{mr: 2}}
                                color={this.props.route.states[0].stage.color}
                            >
                                {this.props.route.states[0].stage.title}
                            </Chip>
                            {
                                this.props.route.completed?
                                    <Chip
                                        sx={{mr: 2}}
                                        color={'success'}
                                    >
                                        Завершен
                                    </Chip>:
                                    null
                            }
                        </Box>
                        {/*{this.state.dcePermission > 0?*/}
                        {/*    <RouteControlledEntityModal*/}
                        {/*        route={this.props.route}*/}
                        {/*    />:*/}
                        {/*    null*/}
                        {/*}*/}
                        <RouteDetail
                            route={this.props.route}
                            callBack={this.props.callBack}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default RouteCard