import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";
import Typography from "@mui/joy/Typography";
import BaseApi from "../../utils/BaseApi";


class CalculatorItemCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            success: false,
            formFields: null,
        };

    }

    async componentDidMount(){
    }

    async constructFormFields(){
        let url = `calculator/models/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        let models = response.data
        return [
            {
                'name': 'title',
                'label': 'Заголовок',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'size',
                'label': 'Размер блока',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 6,
                'type': 'select',
                'options': [
                    {
                        value: 6,
                        label: 'Половина ширины'
                    },
                    {
                        value: 12,
                        label: 'Полная ширина'
                    },
                ]
            },
            {
                'name': 'model',
                'label': 'Модель',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': null,
                'type': 'select',
                'options': models
            },
            {
                'name': 'date_field',
                'label': 'Поле даты (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_date_field',
                'label': 'Поле даты (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'field',
                'label': 'Поле (выбор из списка)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': null,
                'type': 'select',
                'options': []
            },
            {
                'name': 'custom_field',
                'label': 'Поле (свой вариант)',
                'xs': 12,
                'sm': null,
                'md': 6,
                'lg': 6,
                'xl': 6,
                'required': false,
                'default': '',
                'type': 'input',
            },
            {
                'name': 'period',
                'label': 'Период агрегации',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': 'month',
                'type': 'select',
                'options': [
                    {
                        value: 'quarter',
                        label: 'Квартал'
                    },
                    {
                        value: 'month',
                        label: 'Месяц'
                    },
                    {
                        value: 'week',
                        label: 'Неделя'
                    },
                ]
            },
            {
                'name': 'groups',
                'label': 'Группировки',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '[]',
                'type': 'stringList',
            },
            {
                'name': 'queryset_params',
                'label': 'Настройки отбора',
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': false,
                'default': '{}',
                'type': 'keyValuePairs',
            },
            {
                type: 'submit',
                label: 'Добавить'
            }
        ];
    }

    async setModalOpenTrue(){
        let formFields = await this.constructFormFields()
        this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        data.id = crypto.randomUUID();
        data.size = parseInt(data.size);
        let currentCategory = data.field;
        if (!currentCategory) {
            currentCategory = data.custom_field;
            if (!currentCategory) {
                currentCategory = 'count'
            }
        }
        let currentYear = new Date().getFullYear()
        data.charts = [
            {
                years: [currentYear, ],
                'currentCategory': currentCategory,
                yearColors: {},
                colorMode: 'heatmap',
            }
        ]
        data.charts[0].yearColors[currentYear] = this.props.colors[0]
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
                success: false,
            }
        );
    }

    formOnChange(event){
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.success?
                                    <>
                                        <Typography>
                                            Отчет добавлен успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Добавление отчета
                                        </Typography>
                                        <CommonForm
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                            onChange={this.formOnChange}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default CalculatorItemCreate
