import React from "react";
import BaseApi from "../../utils/BaseApi";

//Icons
import BookmarkIcon from '@mui/icons-material/Bookmark';


class CommentFav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorite: this.props.comment.favorite,
        };
    }

    async postFav() {
        const url = `comment/${this.props.comment.id}/`;
        const data = {
            'favorite': !this.state.favorite
        };
        const api = new BaseApi();
        await api.patch(url, data);
        this.setState(
            {favorite: !this.state.favorite}
        );
        this.props.callBack();
    }

    render() {
        return (
            <BookmarkIcon
                color={this.state.favorite?'warning':''}
                fontSize='sm'
                sx={{
                    mr: 1,
                    mt: '2px',
                    "&:hover": {
                        cursor: 'pointer',
                    }
                }}
                onClick={this.postFav.bind(this)}
            />
        )
    }
}

export default CommentFav