import React from "react";
import dayjs from "dayjs";

import Button from "@mui/joy/Button";
import {DatePicker} from "@mui/x-date-pickers";
import {CircularProgress, Stack} from "@mui/joy";

// components
import {CustomDateWrapperField,} from "../Shared/FormFields";
import BaseApi from "../../utils/BaseApi";

// icons
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from "@mui/icons-material/Done";


class FinancialModelForecastStartDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: this.props.componentObj.opportunity,
        };
    }

    async processForm(event) {
        this.setState(
            {
                opportunity: null,
            }
        );
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        const api = new BaseApi();
        const url = `opportunity/${this.state.opportunity.id}/`;
        let response = await api.patch(
            url,
            formData
        );
        this.setState(
            {
                opportunity: response.data,
            }
        );
        await this.props.callBack();
    }

    async clearDate() {
        this.setState(
            {
                opportunity: null,
            }
        );
        const api = new BaseApi();
        const url = `opportunity/${this.state.opportunity.id}/`;
        let response = await api.patch(
            url,
            {
                forecast_start_date: null
            }
        );
        this.setState(
            {
                opportunity: response.data,
            }
        );
        await this.props.callBack();
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress />)
        }
        return (
            <form
                onSubmit={this.processForm.bind(this)}
            >
                <Stack
                    direction={'row'}
                    spacing={1}
                >
                    <CustomDateWrapperField
                        component={DatePicker}
                        label={null}
                        required={false}
                        defaultValue={dayjs(this.state.opportunity.forecast_start_date)}
                        format={'YYYY-MM-DD'}
                        name={'forecast_start_date'}
                    />
                    <Button
                        size={'sm'}
                        type={'submit'}
                        color={'success'}
                        sx={{
                            m: 0,
                            p: 0,
                            pl: 0.5,
                            pr: 0.5,
                        }}
                    >
                        <DoneIcon size={'sm'}/>
                    </Button>
                    <Button
                        size={'sm'}
                        color={'warning'}
                        sx={{
                            m: 0,
                            p: 0,
                            pl: 0.5,
                            pr: 0.5,
                        }}
                        onClick={this.clearDate.bind(this)}
                    >
                        <ClearIcon size={'sm'}/>
                    </Button>
                </Stack>
            </form>
        )
    }
}

export default FinancialModelForecastStartDate