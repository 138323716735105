import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import EditIcon from '@mui/icons-material/Edit';
import CommonForm from "../Shared/CommonForm";


class FinancialModelConfigValueUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
        this.formFields = [
            {
                'name': this.props.name,
                'label': this.props.title,
                'xs': 12,
                'sm': null,
                'md': null,
                'lg': null,
                'xl': null,
                'required': true,
                'default': this.props.defaultValue,
                'type': 'number',
            },
            {
                type: 'submit',
                label: 'Сохранить'
            }
        ]
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <EditIcon
                    size={'sm'}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={this.setModalOpenTrue.bind(this)}
                />
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <CommonForm
                                fields={this.formFields}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default FinancialModelConfigValueUpdate
