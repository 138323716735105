import React from "react";
import HomePage from "./HomePage";

import UserSubscriberContext from "../../context/UserSubscriberContext";
import TaskMyBoard from "../Task/TaskMyBoard";


class BaseHomePage extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {
    }

    render() {
        if (this.context.subscriberObject.external){
            return <TaskMyBoard />
        }
        if (!this.context){
            return null
        }
        else {
            return <HomePage />
        }
    }
}

export default BaseHomePage
