import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

// components
import OpportunityCard from "../Opportunity/OpportunityCard";

// icons
import WebAssetIcon from "@mui/icons-material/WebAsset";


class FinancialModelOpportunityCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    async setModalOpenTrue(){
        this.setState(
            {
                modalOpen: true,
            }
        )
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <OpportunityCard
                                opportunity={this.props.componentObj.opportunity}
                                comments={this.props.componentObj.comments}
                                delivery_states={this.props.componentObj.delivery_states}
                                my_roles={this.props.componentObj.my_roles}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}
export default FinancialModelOpportunityCardWrapper