import React from "react";
import BaseApi from "../../utils/BaseApi";
import {Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";

// icons
import EditIcon from "@mui/icons-material/Edit";

// components
import CommonForm from "../Shared/CommonForm";


class EstimationRevisionUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
        };
    }

    async retrieveFields(){
        const url = `estimation_revision/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return(response.data)
    }

    async setModalOpenTrue(){
        let formFields = await this.retrieveFields();
        this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        const url = `estimation_revision/${this.props.revision.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200) {
            await this.setModalOpenFalse();
        }
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        this.props.callBack();
    }

    render() {
        return (
            <Stack
            >
                <Link
                    level="body-xs"
                    color={'primary'}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <EditIcon sx={{mr: 1}}/>
                    Редактировать
                </Link>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Обновление ревизии трудоемкости
                            </Typography>
                            <CommonForm
                                fields={this.state.formFields}
                                object={this.props.revision}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Stack>
        )
    }
}

export default EstimationRevisionUpdate
