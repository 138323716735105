import React from 'react';
import {Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

// icons
import EditIcon from '@mui/icons-material/Edit';

// components
import CommonForm from "../Shared/CommonForm";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import Chip from "@mui/joy/Chip";


class OpportunityRevenueType extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            revenueType: this.props.revenueType,
            modalOpen: false,
            permission: 0,
            revenueTypes: [],
        };
    }

    async componentDidMount(){
        await this.retrievePermission();
    }

    async retrieveSources(){
        const url = `opp_revenue_type/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                revenueTypes: response.data.results,
            }
        )
    }

    async retrievePermission() {
        let p = await retrievePermission(
            'OpportunityViewSet',
            'partial_update',
            this.context.subscriberObject
        )
        this.setState(
            {
                permission: p,
            }
        )
    }

    async setModalOpenTrue(){
        await this.retrieveSources();
        this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async processForm(form) {
        const url = `opportunity/${this.props.opportunityId}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200) {
            this.setState(
                {
                    revenueType: response.data.revenue_type,
                    modalOpen: false,
                }
            );
        }
    }

    render() {
        return(
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Chip
                    color={this.state.revenueType?'primary':'danger'}
                >
                    {this.state.revenueType?this.state.revenueType.title.substring(0, 40):'Тип выручки не указан'}
                </Chip>
                {
                    this.state.permission > 0?
                        <>
                            <IconButton
                                size="sm"
                                color="primary"
                                onClick={this.setModalOpenTrue.bind(this)}
                            >
                                <EditIcon/>
                            </IconButton>
                            <Modal
                                open={this.state.modalOpen}
                            >
                                <ModalDialog
                                    layout={'center'}
                                    size="lg"
                                >
                                    <ModalClose
                                        onClick={this.setModalOpenFalse.bind(this)}
                                    />
                                    <Box
                                        sx={{
                                            pr: 2,
                                        }}
                                    >
                                        <CommonForm
                                            fields={[
                                                {
                                                    name: 'revenue_type',
                                                    label: 'Тип выручки',
                                                    xs: 12,
                                                    sm: null,
                                                    md: null,
                                                    lg: null,
                                                    xl: null,
                                                    required: true,
                                                    default: null,
                                                    type: 'select',
                                                    options: this.state.revenueTypes.map((each) => {return(
                                                        {'value': each.id, 'label': each.title}
                                                    )})
                                                },
                                                {
                                                    type: 'submit',
                                                    label: 'Сохранить',
                                                    color: 'success',
                                                },
                                            ]}
                                            object={{
                                                revenue_type: this.state.revenueType?this.state.revenueType.id:null
                                            }}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </Box>
                                </ModalDialog>
                            </Modal>
                        </>:
                        null
                }
            </Stack>
        )
    }
}

export default OpportunityRevenueType;