import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import withRouter from "../../utils/withRouter";
import CommonPaginatedComponentList from "../Shared/CommonPaginatedComponentList";
import TaskCard from "./TaskCard";


class TaskMyCompleted extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveTasks();
        }
    }

    async retrieveTasks() {
        const url = 'task/my';
        const api = new BaseApi();
        let params = {
            work_status: 'COM',
            page_size: 10,
            ordering: '-end_dt'
        }
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                tasks: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedComponentList
                    title={'Мои завершенные задачи'}
                    url={'task/completed'}
                    component={TaskCard}
                    componentKeyword={'task'}
                    data={this.state.tasks}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                />
            </Box>
        )
    }
}

export default withRouter(TaskMyCompleted)