import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import CommonModalInner from "../Shared/CommonModalInner";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// Tabs
import TaskTab from "./TaskTab";
import AttachmentsTab from "../Attachment/AttachmentsTab";
import TaskDailyDurationsTab from "./TaskDailyDurationsTab";
import TaskCardInner from "./TaskCardInner";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import WebAssetIcon from '@mui/icons-material/WebAsset';


class TaskDetail extends React.Component {
    static contextType = UserSubscriberContext;

    constructor(props) {
        super(props);
        this.state = {
            task: null,
            modalOpen: false,
        };
    }

    async retrieveTask() {
        this.setState({
            task: null
        });
        const url = `task/${this.props.taskId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                task: response.data
            }
        );
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
        await this.retrieveTask();
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
        if (this.props.callBack) {
            await this.props.callBack();
        }
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        {
                            this.state.task?
                                <CommonModalInner
                                    appName={'Task'}
                                    appId={this.state.task.id}
                                    headerComponent={<TaskCardInner task={this.state.task} hideDetail={true}/>}
                                    defaultTabValue={'info'}
                                    headerOverflowTitle={this.state.task.work_status_human}
                                    headerOverflowColor={this.state.task.work_status_color}
                                    tabComponents={[
                                        {
                                            title: 'Информация',
                                            value: 'info',
                                            icon: <AccountTreeIcon />,
                                            component: <TaskTab
                                                task={this.state.task}
                                                callBack={this.retrieveTask.bind(this)}
                                            />,
                                        },
                                        {
                                            title: 'Вложения',
                                            value: 'attachment',
                                            icon: <AttachFileIcon />,
                                            component: <AttachmentsTab
                                                appId={this.state.task.id}
                                                appName={'Task'}
                                            />,
                                            hidden: this.context.subscriberObject.external,
                                        },
                                        {
                                            title: 'Трудозатраты',
                                            value: 'dailyDurations',
                                            icon: <AccessAlarmIcon />,
                                            component: <TaskDailyDurationsTab
                                                taskId={this.state.task.id}
                                            />,
                                        },
                                    ]}
                                />:
                                <CircularProgress/>
                        }
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default TaskDetail
