import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";
import Typography from "@mui/joy/Typography";


class AttachmentCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
            success: false,
        };
    }

    async componentDidMount(){
        await this.retrieveFields()
    }

    async retrieveFields(){
        const url = `attachment/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                formFields: response.data
            }
        );
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let formData = form.state.formData;
        let fileInput = document.getElementById("form-upload");
        let upload = fileInput.files[0];
        formData.append('related_app_id', this.props.appId);
        formData.append('related_app', this.props.appName);
        formData.append('xtype', this.props.xtype);
        formData.append('upload', upload);
        const url = `attachment/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formData,
            true,
        );
        if (response.status === 201) {
            this.setState(
                {
                    success: true
                }
            );
        }
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
                success: false,
            }
        );
        this.props.callBack();
    }

    render() {
        if (!this.state.formFields){
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.success?
                                    <>
                                        <Typography>
                                            Вложение добавлено успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Добавление вложения
                                        </Typography>
                                        <CommonForm
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default AttachmentCreate
