import React from "react";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

// icons
import CommentIcon from '@mui/icons-material/Comment';
import CommentSidebar from "../Comment/CommentSidebar";


class IdeaNodeComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let data = Object.fromEntries(form.state.formData.entries());
        await this.props.callBack(data);
        this.setModalOpenFalse()
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <CommentIcon sx={{mr: 1}}/> Комментарии
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                pr: 2
                            }}
                        >
                            <CommentSidebar
                                appName={'IdeaNode'}
                                appId={this.props.ideaNode.id}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default IdeaNodeComments
