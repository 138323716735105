import React from 'react';
import Avatar from "@mui/joy/Avatar";
import Chip from "@mui/joy/Chip";

class ProfileHorizontalByProps extends React.Component {
    render() {
        return(
            <Chip
                sx={{
                    mr: 1,
                }}
                startDecorator={
                    <Avatar
                        src={process.env.REACT_APP_API_HOST + this.props.user.avatar}
                        size="sm"
                    />
                }
            >
                {this.props.user.first_name + ' ' + this.props.user.last_name}
            </Chip>
        )
    }
}

export default ProfileHorizontalByProps;