import React from "react";
import {Modal, ModalClose, ModalDialog, Table, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

// icons
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from "@mui/joy/Typography";


class CalculatorItemData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };

    }

    async componentDidMount(){
    }

    async setModalOpenTrue(){
        this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    sx={{'ml': 1,}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <TableViewIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                maxHeight: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Stack
                                spacing={2}
                            >
                                {
                                    Object.entries(this.props.report.total).map(
                                        (year) => (
                                            <>
                                                {Object.keys(year[1]).map((category) =>
                                                    (
                                                        <>
                                                            {
                                                                category.split('_').pop() === 'values' ?
                                                                    <>
                                                                        <Typography
                                                                            level={'h4'}
                                                                        >
                                                                            {year[0]}
                                                                        </Typography>
                                                                        <Table>
                                                                            <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Значение
                                                                                </th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {
                                                                                year[1][category].map(
                                                                                    (each) => (<Typography>{each}</Typography>)
                                                                                )
                                                                            }
                                                                            </tbody>
                                                                        </Table>
                                                                    </> :
                                                                    null
                                                            }
                                                        </>
                                                    )
                                                )}
                                            </>
                                        )
                                    )
                                }
                            </Stack>
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default CalculatorItemData
