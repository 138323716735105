import React from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import Sheet from "@mui/joy/Sheet";
import Box from "@mui/joy/Box";
import { CircularProgress } from "@mui/joy";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";

// components
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";

// Icons
import TelegramIcon from '@mui/icons-material/Telegram';

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";


class CommentSidebar extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.editor = React.createRef();
        this.state = {
            comment_form: <CommentForm ref={this.editor}/>,
        };
    }

    async componentDidMount() {
        let token = JSON.parse(localStorage.getItem("authTokens")).access;
        this.client = new W3CWebSocket(
            `${process.env.REACT_APP_WS_HOST}/ws/comment/${this.props.appName}/${this.props.appId}/?token=${token}`
        );
        this.client.onopen = () => {
            console.log("WebSocket Client Connected");
        };
        this.client.onclose = () => {
            console.log("WebSocket Client Disconnected");
        }
        this.timer = setInterval(() => (this.client.send(JSON.stringify({type: "ping"}))), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    async postComment(){
        let data = {
            type: "comment",
            content: this.editor.current.state.value,
        };
        await this.client.send(
            JSON.stringify(data)
        );

        let r = (Math.random() + 1).toString(36).substring(7);
        this.setState(
            {comment_form: <CommentForm ref={this.editor} initialValue={''} key={r}/>}
        );
    }

    render() {
        if (!this.state.comment_form) {
            return (<CircularProgress />)
        }
        return(
            <Sheet
                variant="plain"
                color="neutral"
                sx={
                    {
                        alignContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 'md',
                        width: '100%',
                        height: '100%',
                        justifyContent: "space-between",
                        alignItems: 'stretch',
                    }
                }
            >
                <Box sx={
                    {
                        display: 'flex',
                        width: '100%',
                        overflowY: 'scroll',
                    }
                }>
                    <CommentList
                        appId={this.props.appId}
                        appName={this.props.appName}
                    />
                </Box>
                <Box sx={
                    {
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }>
                    <Divider
                        sx={{
                            mt: 2,
                            mb: 2
                        }}
                    />
                    {this.state.comment_form}
                    <Button
                        aria-label="commentSubmit"
                        size={'sm'}
                        color="primary"
                        onClick={
                            this.postComment.bind(this)
                        }
                        sx={{mt: 1}}
                    >
                        <TelegramIcon sx={{mr: 2}}/> Отправить
                    </Button>
                </Box>
            </Sheet>
        )
    }
}

export default CommentSidebar;
