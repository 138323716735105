import React from 'react';
import dayjs from "dayjs";

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import Chip from "@mui/joy/Chip";

// context
import UserSubscriberContext from "../../context/UserSubscriberContext";

// components
import withNavigate from "../../utils/withNavigate";
import TaskDetail from "./TaskDetail";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";

// icons
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";


class TaskCardInner extends React.Component{
    static contextType = UserSubscriberContext;

    render(){
        return(
            <>
                <Box
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Typography
                        level={'title-md'}
                        sx={{
                            pt: 0.5,
                            display: 'inline',
                        }}
                    >
                        {this.props.task.title} для&nbsp;
                        {
                            this.context.subscriberObject.external?
                                this.props.task.account.nameshort:
                                <Link onClick={() => this.props.navigate(`/account/detail/${this.props.task.account.id}`)}>
                                    {this.props.task.account.nameshort}
                                </Link>
                        }
                    </Typography>
                    {
                        !this.props.hideDetail?
                            <TaskDetail
                                taskId={this.props.task.id}
                                ref={this.taskDetails}
                                callBack={this.props.callBack}
                            />:
                            null
                    }

                </Box>
                {
                    this.props.task.opportunity ?
                    <Typography
                        level={'title-md'}
                        sx={{'mb': 1}}
                    >
                        к сделке&nbsp;
                        {
                            this.context.subscriberObject.external?
                                this.props.task.opportunity.short_name:
                                <Link onClick={() => this.props.navigate(`/opportunity/detail/${this.props.task.opportunity.id}`)}>
                                    {this.props.task.opportunity.short_name}
                                </Link>
                        }
                    </Typography>:
                    null
                }
                <Box>
                    <ProfileHorizontalByProps
                        user={this.props.task.owner}
                    />
                    <ArrowRightAltIcon
                        sx={
                            {
                                'mr': 1,
                                'display': 'inline-block',
                                'verticalAlign': 'middle',
                            }
                        }
                    />
                    <ProfileHorizontalByProps
                        user={this.props.task.executor}
                    />
                    <Chip
                        color={this.props.task.work_status === 'COM'?'success':dayjs(this.props.task.end_dt)>dayjs()?'success':'danger'}
                    >
                         с {dayjs(this.props.task.start_dt).format('DD.MM.YYYY')} по {dayjs(this.props.task.end_dt).format('DD.MM.YYYY')}
                    </Chip>
                </Box>
            </>
        )
    }
}

export default withNavigate(TaskCardInner)
