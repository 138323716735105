import React from "react";

import {CircularProgress, Switch} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";


class FinancialModelForecastPayCert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: this.props.componentObj,
        };
    }

    async onChange() {
        const api = new BaseApi();
        let response = await api.patch(
            `${this.props.url}/${this.state.obj.id}/`,
            {
                forecast: !this.state.obj.forecast
            }
        );
        this.setState({
            obj: response.data
        })
        await this.props.callBack();
    }

    render() {
        if (!this.state.obj) {
            return (<CircularProgress />)
        }
        return (
            <Switch
                // color={'success'}
                checked={this.state.obj.forecast}
                onChange={this.onChange.bind(this)}
            />
        )
    }
}

export default FinancialModelForecastPayCert