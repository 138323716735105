import {createContext, useState, useEffect} from "react";
import BaseApi from "../utils/BaseApi";


const UserSubscriberContext = createContext({});
export default UserSubscriberContext;


export const UserSubscriberProvider = ({children}) => {
    const [userObject, setUserObject] = useState({});
    const [subscriberObject, setSubscriberObject] = useState({});
    const [loading,setLoading] = useState(true);

    const getData = async () => {
        const api = new BaseApi();
        let response = await api.get(
            'user/me',
            {}
        );
        setUserObject(response.data);
        response = await api.get(
            'subscriber/me',
            {}
        );
        setSubscriberObject(response.data);
    }

    useEffect(()=> {
        getData().then(
            () => {
                setLoading(false)
            }
        )
    },[])

    let contextData = {
        userObject: userObject,
        subscriberObject: subscriberObject,
    };

    return (
        <UserSubscriberContext.Provider value={contextData}>
            {
                loading?
                    <>No user/subscriber data</>:
                    children
            }
        </UserSubscriberContext.Provider>
    );
};